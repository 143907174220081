import React, { useEffect, useRef } from 'react';

import { ScrollTrigger } from 'app/lib/gsap';

import img from 'app/assets/phone-horizontal.png';

import './style.scss';

const OScanner = () => {
  const trigger = useRef();
  const pin = useRef();

  useEffect(() => {
    let scrollTrigger
    ScrollTrigger.matchMedia({
      '(min-width: 767px) and (max-width: 1279px)': () => {
        scrollTrigger = ScrollTrigger.create({
          trigger: trigger.current,
          start: 'top 50%-=170px', 
          end: 'top 50%-=1128px', 
          pin: pin.current,
        });
      },
      '(min-width: 1280px)': () => {
        scrollTrigger = ScrollTrigger.create({
          trigger: trigger.current,
          start: 'top 50%-=208px', 
          end: 'top 50%-=1156px',
          pin: pin.current,
        });
      },
    });
      
    return () => {
      ScrollTrigger.clearMatchMedia();
      scrollTrigger?.kill();
    };
  }, []);

  return (
    <section className="o-phone-section">
      <div className="wrapper">
        <div className="o-phone-content">
          <div className="o-phone-wrap" ref={trigger}>
            <div className="o-phone-scanner" ref={pin}>
              <img src={img} alt="phone" />
            </div>
          </div>
          <p className="o-phone-txt">
            Get your new audience. Reach new frontiers of users. 
            Discover new promotion tools for your organization. 
            Become a new reason to bring people together in a new world.
          </p>
        </div>
      </div>
    </section>
  );
};

export default OScanner;