import React, { useState } from 'react';
import { Form, Formik } from 'formik';

import { logEvent } from 'app/analytics';
import { LogEvent } from 'app/constants';
import * as firestore from 'app/firestore';
import { uploadDocument } from 'app/utils';
import { InputField, Button, Select, InputFile } from 'app/components';

import { validationSchema, options, OrganizationType } from './config';

import './style.scss';

const OApplication = () => {
  const [successVisible, setSuccessVisible] = useState(false);

  const onSubmitHandler = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);
      const organization = values;
      const { user } = await firestore.signIn();

      if (organization.file) {
        const { url } = await uploadDocument(user.uid, organization.file);
        organization.file = url;
      }

      await firestore.submitOrganization(organization);
      await user.delete();

      logEvent(LogEvent.SubmitOrganization);
      setSuccessVisible(true);
    } catch (error) {
      alert(error.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <section className="o-application-section">
      <div className="wrapper">
        <div className="o-application-content">
          <div className="o-application-content__txt">
            <h6 className="_title">Submit your application</h6>
            <p className="_txt">
              Leave an application for placement of your organization in the We
              Are One application.
            </p>
          </div>
          <div className="o-application-content__form">
            {successVisible ? (
              <div className="o-application-form-success">
                <h6>Thanks for the application!</h6>
                <p>We will review it within 3 business days.</p>
                <Button
                  type="button"
                  theme="secondary"
                  onClick={() => setSuccessVisible(false)}
                >
                  Okay
                </Button>
              </div>
            ) : (
              <Formik
                enableReinitialize
                initialValues={{}}
                onSubmit={onSubmitHandler}
                validationSchema={validationSchema}
              >
                {({ dirty, isValid, isSubmitting, values, field }) => (
                  <Form className="o-application-form" autoComplete="off">
                    <p className="o-application-form-category">
                      <b>Contact details</b>
                    </p>
                    <div className="o-application-form-row">
                      <InputField placeholder="Full Name" name="fullName" />
                    </div>
                    <div className="o-application-form-row">
                      <InputField
                        type="email"
                        name="email"
                        placeholder="Email"
                      />
                    </div>
                    <p className="o-application-form-category">
                      <b>Organization details</b>
                    </p>
                    <div className="o-application-form-row">
                      <InputField
                        name="organizationName"
                        placeholder="Organization name"
                      />
                    </div>
                    <div className="o-application-form-row">
                      <Select
                        valueKey="value"
                        options={options}
                        name="organizationType"
                        placeholder="Organization type"
                      />
                    </div>
                    <div className="o-application-form-row">
                      <InputField
                        name="websiteLink"
                        placeholder="Website link"
                      />
                    </div>
                    {values.organizationType === OrganizationType.NonProfit && (
                      <div className="o-application-form-row">
                        <InputFile placeholder="Attach file" name="file" />
                      </div>
                    )}
                    <div className="o-application-form-row _btn">
                      <Button
                        type="submit"
                        theme="secondary"
                        loading={isSubmitting}
                        disabled={!dirty || !isValid}
                      >
                        Create account
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default OApplication;
