import React from 'react';
import { Field } from 'formik';
import Input from './index';

const InputField = ({ name, ...props }) => (
  <Field name={name}>
    {({ field: { onChange, onBlur, value, onFocus }, meta }) => (
      <Input 
        name={name}
        meta={meta}
        onBlur={onBlur} 
        onFocus={onFocus} 
        onChange={onChange} 
        value={value ?? ''} 
        {...props} 
      />
    )}
  </Field>
);

export default InputField;
