import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel,Pagination, EffectFade, Controller } from 'swiper';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';

import { Responsive, Button, ScrollTopButton } from 'app/components';
import { gsap, ScrollTrigger } from 'app/lib/gsap';

import slide1 from 'app/assets/h-slider/slide-1.png';
import slide2 from 'app/assets/h-slider/slide-2.png';
import slide3 from 'app/assets/h-slider/slide-3.png';
import slide4 from 'app/assets/h-slider/slide-4.png';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

import './style.scss';

const MAX_SLIDE = 3;

const HSlider = () => {
  const location = useLocation();
  const [slide, setSlide] = useState(0);
  const [skipFreeze, setSkipFreeze] = useState(false);
  const [controlledSwiper, setControlledSwiper] = useState(null);
  const [downScrollPinned, setDownScrollPinned] = useState(false);
  const [upScrollPinned, setUpScrollPinned] = useState(false);
  const [scrollDirection, setScrollDirection] = useState('down');
  const isDesktop = useMediaQuery({ minWidth: Responsive.TABLET_MAX_WIDTH + 1 });
  const animationSection = useRef();
  const animationScale = useRef();
  const swiper = useRef();
  const lastScrollTop = useRef();

  useEffect(() => {
    lastScrollTop.current = 0;
  }, [lastScrollTop]);

  const enableSwiper = useCallback(() => {
    if (!controlledSwiper) {
      return;
    }
    document.body.classList.add('_freezed_scroll');
    controlledSwiper.enable();
  }, [controlledSwiper]);

  const disableSwiper = useCallback(() => {
    if (!controlledSwiper) {
      return;
    }
    document.body.classList.remove('_freezed_scroll');
    controlledSwiper.disable();
  }, [controlledSwiper]);

  const handleScroll = useCallback((e) => {
    if (!animationSection.current || !e || lastScrollTop.current === undefined) {
      return;
    }
    const st = window.pageYOffset || document.documentElement.scrollTop;
    if (skipFreeze) {
      if (st <= 10) {
        setSkipFreeze(false);
        enableSwiper();
        controlledSwiper.slideTo(0);
        disableSwiper();
        setUpScrollPinned(true);
        setDownScrollPinned(false);
        return;
      }
      return;
    }
    if (st > lastScrollTop.current) {
      setScrollDirection('down');
    } else {
      setScrollDirection('up');
    }
    lastScrollTop.current = st <= 0 ? 0 : st;

    
    const offset = animationSection.current.offsetTop + animationSection.current.clientHeight / 2;
    const scrollDiff = offset - window.pageYOffset;
    
    
    if (scrollDirection === 'down') {
      if (!downScrollPinned && scrollDiff <= 0) {
        setDownScrollPinned(true);
        window.scrollTo(0, offset);
        enableSwiper();
      }
    } else if (scrollDirection === 'up') {
      if (!upScrollPinned && downScrollPinned && scrollDiff >= 0) {
        setUpScrollPinned(true);
        window.scrollTo(0, offset);
        enableSwiper();
      }
    }

  }, [enableSwiper, scrollDirection, animationSection, downScrollPinned, upScrollPinned, skipFreeze, disableSwiper, controlledSwiper]);

  useEffect(() => {
    if (!isDesktop) {
      return;
    }

    handleScroll();
    window.addEventListener('scroll', handleScroll, false);

    return () => {
      window.removeEventListener('scroll', handleScroll, false);
    };
  }, [handleScroll, isDesktop]);

  const swiperSettings = useMemo(() => {
    if (isDesktop) {
      return {
        modules: [Mousewheel, Pagination, EffectFade, Controller],
        direction: 'vertical',
        mousewheel: {
          sensitivity: 1,
          forceToAxis: true,
          eventsTarget: '.h-slider-section',
        },
        preventInteractionOnTransition: true,
        onSwiper: setControlledSwiper,
        onSlideChangeTransitionEnd: ({ activeIndex, previousIndex }) => {
          if (activeIndex === MAX_SLIDE) {
            disableSwiper();
            setUpScrollPinned(false);
          }
          if (activeIndex === 0 && previousIndex > 0) {
            disableSwiper();
            setDownScrollPinned(false);
          }
        },
      };
    } else {
      return {
        modules: [Pagination, EffectFade],
        direction: 'horizontal',
      };
    }
  }, [isDesktop, disableSwiper]);

  useEffect(() => {
    let scrollTrigger
    ScrollTrigger.matchMedia({
      '(max-width: 767px)': function() {
        scrollTrigger = gsap.timeline({
          scrollTrigger: {
            trigger: animationSection.current,
            scrub: true,
            start: 'top top',
            end: 'bottom bottom',
          },
        })
        .fromTo(
          animationScale.current,
          {
            scale: window.innerWidth / 180,
          },
          {
            scale: 1,
          },
        );
      },
      '(min-width: 768px) and (max-width: 1279px)': function() {
        scrollTrigger = gsap.timeline({
          scrollTrigger: {
            trigger: animationSection.current,
            scrub: true,
            start: 'top top',
            end: 'bottom bottom',
          },
        })
        .fromTo(
          animationScale.current,
          {
            scale: window.innerWidth / 258,
          },
          {
            scale: 1,
          },
        );
      },
      '(min-width: 1280px)': function() {
        scrollTrigger = gsap.timeline({
          scrollTrigger: {
            trigger: animationSection.current,
            scrub: true,
            start: 'top top',
            end: 'bottom bottom',
          },
        })
        .fromTo(
          animationScale.current,
          {
            scale: window.innerWidth / 300,
          },
          {
            scale: 1,
          },
        );
      },
    });
      
    return () => {
      ScrollTrigger.clearMatchMedia();
      scrollTrigger.scrollTrigger.kill();
    };

  }, [enableSwiper]);

  const onJoinClick = useCallback(() => {
    if (!controlledSwiper) {
      return;
    }
    enableSwiper();
    controlledSwiper.slideTo(MAX_SLIDE);
    disableSwiper();
    setUpScrollPinned(false);
    setDownScrollPinned(true);
    setTimeout(() => {
      ScrollTrigger.refresh();
      ScrollTrigger.update();
    }, 100);
  }, [controlledSwiper, enableSwiper, disableSwiper]);

  useEffect(() => {
    if (location?.hash) {
      const el = document.querySelector(location.hash);
      if (el) {
        onJoinClick();
        setTimeout(() => {
          el.scrollIntoView({ block: 'start' });
        }, 0);
      }
    }
  }, [location?.hash, onJoinClick]);

  return (
    <>
      <section className="h-slider-animation-section" ref={animationSection}>
        <section className="h-slider-animation-sticky">
          <section className="h-slider-section" ref={animationScale}>
            <div className="h-slider-swiper" ref={swiper}>
              <div className="h-slider-progress">
                <i className="h-slider-progress__indicator" style={{transform: `rotate(${(90 * slide)}deg)`}}></i>
              </div>
              <Swiper 
                enabled={isDesktop ? false : true}
                effect="fade"
                fadeEffect={{
                  crossFade: true,
                }}
                speed={1000}
                slidesPerView={1}  
                pagination={{
                  clickable: true
                }} 
                onSlideChange={({ activeIndex }) => {
                  setSlide(activeIndex);
                }}
                {...swiperSettings}
              >
                <SwiperSlide>
                  <div className="h-slider-item">
                    <div className="wrapper">
                      <div className="h-slider-item-content">
                        <i className="_illustration-1 hidden-mobile" />
                        <img className="h-slider-item-img" src={slide1} alt="slide 1" />
                        <p className="h-slider-item-txt _1">
                          Contribute your <b>time</b><br/>
                          to positive organizations around you.
                        </p>
                        <HashLink to="#create-account" onClick={onJoinClick}>
                          <Button className="h-slider-item-btn visible-desktop">Join</Button>
                        </HashLink>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="h-slider-item">
                    <div className="wrapper">
                      <div className="h-slider-item-content">
                      <i className="_illustration-2 visible-desktop" />
                      <i className="_illustration-3 hidden-mobile" />
                        <img className="h-slider-item-img" src={slide2} alt="slide 2" />
                        <p className="h-slider-item-txt _2">
                          <b>Contribute your</b> wealth <br/>
                          to causes you are care about.
                        </p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="h-slider-item">
                    <div className="wrapper">
                      <div className="h-slider-item-content">
                      <i className="_illustration-4 visible-desktop" />
                      <i className="_illustration-5 visible-desktop" />
                        <img className="h-slider-item-img" src={slide3} alt="slide 3" />
                        <p className="h-slider-item-txt _3">
                          Discover <b>Good News</b> moments, positivity around you.
                        </p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="h-slider-item">
                    <div className="wrapper">
                      <div className="h-slider-item-content">
                      <i className="_illustration-6 hidden-mobile" />
                      <i className="_illustration-7 visible-desktop" />
                      <i className="_illustration-8 visible-desktop" />
                        <img className="h-slider-item-img" src={slide4} alt="slide 4" />
                        <p className="h-slider-item-txt _4">
                          <b>Interact</b> with liked minded Citizens and form their own tribes to do good together.
                        </p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
              <i className="h-slider-phone" />
            </div>
          </section>
        </section>
      </section>
      <ScrollTopButton onClick={() => setSkipFreeze(true)} />
    </>
  );
};

export default HSlider;