import React from 'react';

import img from '../../assets/o-event-card-img.png'

import './style.scss';

const OEvents = () => {
  return (
    <section className="o-events-section">
      <div className="wrapper">
        <div className="o-events-content">
          <div className="o-events-card">
            <div className="o-events-card__desc">
              <h3 className="_title">Create events and unite people into a charitable community who can join you</h3>
              <p className="_txt">
                A simple process of creating and joining an event will allow you to recruit a large number of participants
              </p>
            </div>
            <div className="o-events-card__img">
              <img src={img} alt="phone" />
            </div>
          </div>
          <ul className="o-events-list-info">
            <li className="o-events-list-info-item">
              <h4 className="o-events-list-info-item__title">Simplicity</h4>
              <p className="o-events-list-info-item__txt">Simple and easy event creation process. Simply fill out the form with all the necessary information</p>
            </li>
            <li className="o-events-list-info-item">
              <h4 className="o-events-list-info-item__title">Promotion</h4>
              <p className="o-events-list-info-item__txt">Events allow organizations to promote on the platform as well as spread through word of mouth</p>
            </li>
            <li className="o-events-list-info-item">
              <h4 className="o-events-list-info-item__title">Modernity</h4>
              <p className="o-events-list-info-item__txt">Events in social networks are a modern take on ordinary meetings in a new reality</p>
            </li>
            <li className="o-events-list-info-item">
              <h4 className="o-events-list-info-item__title">Practicality</h4>
              <p className="o-events-list-info-item__txt">Thanks to events, you can quickly close the need to find the right people for your organization</p>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default OEvents;