import React from 'react';

import img from 'app/assets/h-hero.svg';

import './style.scss';

const HHero = () => {
  return (
    <section className="h-hero-section">
      <div className="wrapper">
        <div className="h-hero-content">
          <h1 className="h-hero-title">
            You are about to become <br/>a <b>Citizen of Life</b>.
          </h1>
          <h2 className="h-hero-title">
            We are about to become <br/>a <b>global society</b>.
          </h2>
          <div className="h-hero-img">
            <img src={img} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HHero;