import React from 'react';
import { Link } from 'react-router-dom';

import logo from 'app/assets/logo.png';

import { Icon } from 'app/components';

import { NAVIGATION, APP_ROUTES } from 'app/constants';

import './style.scss';

const Footer = () => {
  const onNavigate = () => {
    window.scrollTo(0, 0);
  };

  return (
    <footer className="footer-section">
      <div className="wrapper">
        <div className="footer-content">
          <div className="footer-row">
            <Link to={APP_ROUTES.Home} className="footer-logo">
              <img src={logo} alt=" We Are One" />
            </Link>
            <ul className="footer-menu">
              {NAVIGATION.map(({label, url, icon}) => (
                <li key={label}>
                  <Link to={url} onClick={onNavigate}>
                    {icon ? <Icon icon={icon} /> : null}
                    {label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="footer-row _btm">
            <p className="footer-copy">Copyright – We Are One. Privacy Policy</p>
            <div className="footer-social">
              <b className="footer-social__label">Follow us:</b>
              <ul className="footer-social__menu">
                <li>
                  <a 
                    href="https://www.instagram.com/weareone.usa/" 
                    target="_blank" 
                    rel="nofollow noopener noreferrer"
                  >
                    <Icon icon="instagram" />
                  </a>
                </li>
              </ul>
            </div>
            <p className="footer-designed">
              <Icon icon="sweetcode" />
              <span>Designed by </span>
              <a 
                href="https://sweetcode.pro/" 
                target="_blank" 
                rel="nofollow noopener noreferrer"
              >
                <b>Sweetcode Lab</b>
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
};

export default Footer;