import React, { useEffect, useRef } from 'react';

import phone from 'app/assets/o-info/phone-vertical.png';

import { gsap, ScrollTrigger } from 'app/lib/gsap';

import './style.scss';

const OInfo = () => {
  const stepSection1 = useRef();
  const stepSection2 = useRef();
  const stepSection3 = useRef();
  const step1ContentTxt = useRef();
  const step2ContentTxt = useRef();
  const step3ContentTxt = useRef();
  const imgPhone2 = useRef();
  const imgPhone3 = useRef();
  const imgItem1 = useRef();
  const imgItem3 = useRef();
  const img = useRef();
  const infoContent = useRef();
  const progress = useRef();
  const progressLine = useRef();
  const progressDot = useRef();

  useEffect(() => {
    let section1ScrollTrigger, section2ScrollTrigger, section3ScrollTrigger, progressScrollTrigger;
    ScrollTrigger.matchMedia({
      '(min-width: 768px)': () => {
        section1ScrollTrigger = gsap.timeline({
          scrollTrigger: {
            trigger: stepSection1.current,
            scrub: true,
            start: 'top bottom',
            end: 'bottom bottom',
            onEnter: () => {
              imgItem1.current.classList.add('_visible');
            },
            onEnterBack: () => {
              imgItem1.current.classList.add('_visible');
            },
            onLeave: () => {
              imgItem1.current.classList.remove('_visible');
            },
          },
        })
        .to(
          step1ContentTxt.current, 
          {
            translateY: '-40px',
            duration: 0.9,
          },
          0.1,
        )
        .to(
          progressLine.current,
          {
            translateY: '-36.98%',
            duration: 0.9,
          },
          0.10,
        )
        .fromTo(
          progressDot.current, 
          { 
            backgroundColor: '#cc5aaa', 
            duration: 1, 
            ease: 'none', 
          },
          { 
            backgroundColor: '#8265D1', 
            duration: 1, 
            ease: 'none', 
          },
          0,
        )
        .to(
          step1ContentTxt.current, 
          {
            opacity: 0,
            duration: 0.10,
          },
          0.85,
        );
      
        section2ScrollTrigger = gsap.timeline({
          scrollTrigger: {
            trigger: stepSection2.current,
            scrub: true,
            start: 'top bottom',
            end: 'bottom bottom',
            onEnter: () => {
              imgPhone2.current.classList.add('_visible');
            },
            onLeaveBack: () => {
              imgPhone2.current.classList.remove('_visible');
            },
            toggleClass: {
              targets: '.o-info-content-img .img-item._second', 
              className: '_visible',
            },
          }
        })
        .to(
          progressLine.current,
          {
            translateY: '-70.83%',
            duration: 1,
          },
        )
        .fromTo(
          progressDot.current, 
          { 
            backgroundColor: '#8265D1', 
            duration: 1, 
            ease: 'none', 
          },
          { 
            backgroundColor: '#C37D82', 
            duration: 1, 
            ease: 'none', 
          },
          0,
        )
        .to(
          step2ContentTxt.current, 
          {
            opacity: 1,
            duration: 0.10,
          },
          0,
        )
        .to(
          step2ContentTxt.current, 
          {
            translateY: '-40px',
            duration: 1,
          },
          0,
        )
        .to(
          step2ContentTxt.current, 
          {
            opacity: 0,
            duration: 0.10,
          },
          0.85,
        );
      
        section3ScrollTrigger = gsap.timeline({
          scrollTrigger: {
            trigger: stepSection3.current,
            scrub: true,
            start: 'top bottom',
            end: 'bottom bottom',
            onEnter: () => {
              imgPhone3.current.classList.add('_visible');
              imgItem3.current.classList.add('_visible');
            },
            onLeaveBack: () => {
              imgPhone3.current.classList.remove('_visible');
              imgItem3.current.classList.remove('_visible');
            },
          }
        })
        .to(
          step3ContentTxt.current, 
          {
            opacity: 1,
            duration: 0.10,
          },
        )
        .to(
          step3ContentTxt.current, 
          {
            translateY: '-40px',
            duration: 1,
          },
          0,
        );
      
        progressScrollTrigger = ScrollTrigger.create({
          trigger: infoContent.current,
          start: 'top top', 
          end: 'bottom bottom',
          pin: progressDot.current,
          pinSpacing: false,
        });
      },
    }); 
      
    return () => {
      ScrollTrigger.clearMatchMedia();
      progressScrollTrigger?.kill();
      section1ScrollTrigger?.scrollTrigger?.kill();
      section2ScrollTrigger?.scrollTrigger?.kill();
      section3ScrollTrigger?.scrollTrigger?.kill();
    };
  }, []);

  return (
    <section className="o-info-section">
      <div className="o-info-content" ref={infoContent}>
        <div className="o-info-content-item _first">
          <div className="wrapper">
            <div className="o-info-content-wrapper">
              <div className="o-info-img">
                <div className="img-phone _first _visible" />
                <div className="img-item _first _visible" />
                <img src={phone} alt="" className="img-hardware" />
              </div>
              <div className="o-info-content-item__txt" ref={step1ContentTxt}>
                <h5>Volunteer Recruitment</h5>
                <p>
                  By using our platform, you have the opportunity to recruit volunteers 
                  for your organization. You will receive all applications sent by 
                  users in your admin panel.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="o-info-content-item _second">
          <div className="wrapper">
            <div className="o-info-content-wrapper">
              <div className="o-info-img">
                <div className="img-phone _second _visible" />
                <div className="img-item _second _visible" />
                <img src={phone} alt="" className="img-hardware" />
              </div>
              <div className="o-info-content-item__txt" ref={step2ContentTxt}>
                <h5>Feedback <br/>system</h5>
                <p>
                  Get feedback from users, this will give you the opportunity to evaluate 
                  the level of your organization, as well as move forward within the application.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="o-info-content-item _third">
          <div className="wrapper">
            <div className="o-info-content-wrapper">
              <div className="o-info-img">
                <div className="img-phone _third _visible" />
                <div className="img-item _third _visible" />
                <img src={phone} alt="" className="img-hardware" />
              </div>
              <div className="o-info-content-item__txt" ref={step3ContentTxt}>
                <h5>Donation</h5>
                <p>
                  You can receive and track the dynamics of donations in our application. 
                  And also receive donations in <b>Energia coins</b> and withdraw them into fiat money
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="o-info-content-img hidden-mobile">
          <div className="wrapper">
            <div className="o-info-img" ref={img}>
              <div className="img-phone _first _visible" />
              <div className="img-phone _second" ref={imgPhone2} />
              <div className="img-phone _third" ref={imgPhone3} />
              <div className="img-item _first _visible" ref={imgItem1} />
              <div className="img-item _second" />
              <div className="img-item _third" ref={imgItem3} />
              <img src={phone} alt="" className="img-hardware" />
            </div>
          </div>
        </div>
        <div className="o-info-content-progress" ref={progress}>
          <div className="o-info-content-progress__line" ref={progressLine}>
            <i className="_dot"></i>
            <i className="_dot"></i>
            <i className="_dot"></i>
          </div>
          <i className="o-info-content-progress__dot" ref={progressDot}></i>
        </div>
      </div>
      <div className="o-info-step-section step1" ref={stepSection1} />
      <div className="o-info-step-section step2" ref={stepSection2} />
      <div className="o-info-step-section step3" ref={stepSection3} />
    </section>
  );
};

export default OInfo;