import React from 'react';

import img from 'app/assets/concat-page-img.png';

import './style.scss';

const ContactPageImg = () => {
  return (
    <div className="cp-img">
      <img src={img} alt="" />
      <i className="_arrow _left"></i>
      <i className="_arrow _right"></i>
    </div>
  )
};

export default ContactPageImg;