import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { Form, Formik } from 'formik';
import parse from 'html-react-parser';
import { RecaptchaVerifier } from 'firebase/auth';

import { auth } from 'app/utils';
import { logEvent } from 'app/analytics';
import { LogEvent } from 'app/constants';
import * as firestore from 'app/firestore';
import {
  Button,
  InputField,
  PhoneInputField,
  VerificationInputField,
} from 'app/components';

import { validationSchema as schema } from './config';

import './style.scss';

const copyDefault = {
  title: "Become a Citizen",
  desc: `The first 100 users of the <b>We Are One</b> will receive 50 Energiacoins`,
  btn: "Apply",
};

const CreateAccountForm = ({
  onSumbit,
  copy = copyDefault,
  withRecaptcha = true,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [verification, setVerification] = useState(null);
  const [successVisible, setSuccessVisible] = useState(false);
  const [captchaVisible, setCaptchaVisible] = useState(false);

  const validationSchema = useMemo(() => {
    return schema(verification);
  }, [verification]);

  const submitButtonType = useMemo(() => {
    if (withRecaptcha) {
      return verification ? "submit" : "button";
    }

    return "submit";
  }, [withRecaptcha, verification]);

  const handleSubmit = useCallback(
    (phone) => {
      if (withRecaptcha) {
        return verification ? undefined : () => signInWithPhone(phone);
      }

      return undefined;
    },
    [withRecaptcha, verification]
  );

  const onSubmitHandler = useCallback(
    async (values) => {
      try {
        setIsLoading(true);
        await onSumbit(values, verification);

        setSuccessVisible(true);
        if (withRecaptcha) {
          window.recaptchaVerifier = undefined;
        }
      } catch (error) {
        alert(error.message);
      } finally {
        setIsLoading(false);
      }
    },
    [withRecaptcha, onSumbit, verification]
  );

  useEffect(() => {
    setCaptchaVisible(!successVisible);
  }, [successVisible]);

  const signInWithPhone = async (phone) => {
    try {
      setIsLoading(true);
      if (!window.recaptchaVerifier) {
        window.recaptchaVerifier = new RecaptchaVerifier(
          "recaptcha-container",
          { size: "invisible" },
          auth
        );
      }

      const response = await firestore.signInWithPhone(
        phone,
        window.recaptchaVerifier
      );

      logEvent(LogEvent.CreateAccount);
      setVerification(response);
    } catch (error) {
      alert(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="account-form-wrap">
      {successVisible ? (
        <div className="account-form-success">
          <p className="account-title">
            Congratulations, <br />
            you are one of the first citizen of We Are One world!
          </p>
          <p className="account-txt">
            We will send you an email as soon as our application is released on
            App Store and Play Market.
          </p>
          <Button
            type="button"
            theme="outlined"
            onClick={() => {
              setSuccessVisible(false);
              setVerification(null);
            }}
          >
            Okay
          </Button>
        </div>
      ) : (
        <Formik
          enableReinitialize
          initialValues={{}}
          onSubmit={onSubmitHandler}
          validationSchema={validationSchema}
        >
          {({ dirty, isValid, values }) => (
            <Form className="account-form" autoComplete="off">
              <h4 className="account-title">{copy.title}</h4>
              <p className="account-txt">{parse(copy.desc)}</p>
              {verification ? (
                <>
                  <div className="account-form-row">
                    <p className="account-form-row-label">
                      <b>Confirm Phone Number</b>
                    </p>
                    <VerificationInputField name="code" />
                  </div>
                  <div
                    className="account-form-resend-code"
                    onClick={() => signInWithPhone(values.phone)}
                  >
                    <span className="account-form-resend-code-btn">
                      <b>Resend Code</b>
                    </span>
                  </div>
                </>
              ) : (
                <>
                  <div className="account-form-row">
                    <PhoneInputField name="phone" />
                  </div>
                  <div className="account-form-row">
                    <InputField type="email" name="email" placeholder="Email" />
                  </div>
                </>
              )}
              <div className="account-form-row _btn">
                <Button
                  theme="outlined"
                  loading={isLoading}
                  type={submitButtonType}
                  disabled={!dirty || !isValid}
                  onClick={handleSubmit(values.phone)}
                >
                  {verification ? "Continue" : copy.btn}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
      {captchaVisible && <div id="recaptcha-container" />}
    </div>
  );
};

export default CreateAccountForm;
