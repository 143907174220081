import * as Yup from "yup";

import { required, email } from "app/utils";

export const OrganizationType = Object.freeze({
  NonProfit: 'NonProfit',
  Commercial: 'Commercial',
});

export const validationSchema = Yup.object().shape({
  fullName: required,
  email,
  organizationName: required,
  organizationType: required,
  websiteLink: required,
  file: Yup.mixed().when("organizationType", {
    is: OrganizationType.NonProfit,
    then: Yup.mixed().required("Please, provide file"),
    otherwise: Yup.mixed().optional(),
  }),
});

const Title = Object.freeze({
  [OrganizationType.NonProfit]: 'Non-profit',
  [OrganizationType.Commercial]: 'Commercial',
});

export const options = Object
  .keys(OrganizationType)
  .map(type => ({ value: type, label: Title[type] }))


