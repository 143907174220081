import React from 'react';

import './style.scss';

const HVisionMisson = () => {
  return (
    <section className="h-vm-section" data-scroll-section>
      <div className="wrapper">
        <div className="h-vm-content">
          <div className="h-vm-item">
            <h3 className="h-vm-item__title" data-scroll data-scroll-repeat="true" data-scroll-offset="10%, 48%">Our Vision</h3>
            <p className="h-vm-item__txt">
              <span data-scroll data-scroll-repeat="true" data-scroll-offset="52%, 48%">Create a beautiful life everywhere for every </span>
              <span data-scroll data-scroll-repeat="true" data-scroll-offset="52%, 48%">species on <b>planet Earth</b>.</span>
            </p>
          </div>
          <div className="h-vm-item">
            <h3 className="h-vm-item__title" data-scroll data-scroll-repeat="true" data-scroll-offset="45%, 48%">Our Mission</h3>
            <p className="h-vm-item__txt">
              <span data-scroll data-scroll-repeat="true" data-scroll-offset="52%, 48%">Accelerate humankind’s </span>
              <span data-scroll data-scroll-repeat="true" data-scroll-offset="52%, 10%">transition to a positive global society.</span>
            </p>
          </div>
          <i className="_bg-right"></i>
        </div>
      </div>
    </section>
  );
};

export default HVisionMisson;