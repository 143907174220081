import React from 'react';

import './style.scss';

const OIntro = () => {
  return (
    <section className="o-intro-section">
      <div className="wrapper">
        <div className="o-intro-content">
          <h2 className="o-intro-title">
            Take your organization <br/>to the next level
          </h2>
          <p className="o-intro-txt">
            Placing your organization in the <strong>We Are One</strong> application 
            allows you to reach a new audience and get the chance for regular donations from the users.
          </p>
        </div>
      </div>
    </section>
  );
};

export default OIntro;