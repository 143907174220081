export const APP_ROUTES = {
  Home: '/',
  ContactUs: '/contact-us',
  Organizations: '/for-organizations',
  Energiacoin: '/energiacoin',
}

export const NAVIGATION = [
  {
    label: 'For organizations',
    url: APP_ROUTES.Organizations,
  },
  // {
  //   label: 'Energiacoin',
  //   url: APP_ROUTES.Energiacoin,
  // },
  {
    label: 'Contact us',
    url: APP_ROUTES.ContactUs,
  },
]

export const LogEvent = Object.freeze({
  ScreenView: 'screen_view',
  CreateAccount: 'create_account',
  SubmitOrganization: 'submit_organization'
})