import * as Yup from 'yup';
import { isPossiblePhoneNumber } from 'react-phone-number-input';

export const phone = Yup.string()
  .trim()
  .required('Required field')
  .test('valid-phone', 'Incorrect phone', (value) =>
    value ? isPossiblePhoneNumber(value) : true
  );

export const required = Yup.string().trim().required('Required field');

export const email = Yup.string().trim().email().required('Required field');

export const varificationCode = Yup.string()
  .trim()
  .required('Required field')
  .min(6, 'Must be exactly 6 digits')
  .max(6, 'Must be exactly 6 digits');
