import React from 'react';
import cx from 'classnames';

import './style.scss';

const Input = ({ label, type = 'text', placeholder, name, meta, className, textarea, ...props }) => {
  return (
    <div className="control-input">
      {label && (
        <label htmlFor={name} className="control-input__label">
          {label}
        </label>
      )}
      {textarea ? (
        <textarea 
          name={name} 
          type={type} 
          placeholder={placeholder} 
          className={cx(className, {_error: meta?.touched && meta?.error})}
          {...props} 
        ></textarea>
      ) : (
        <input 
          name={name} 
          type={type} 
          placeholder={placeholder} 
          className={cx(className, {_error: meta?.touched && meta?.error})}
          {...props} 
        />
      )}
      <i className="control-input__border" />
      {meta?.touched && meta?.error && (
        <div className="control-error">
          {meta.error}
        </div>
      )}
    </div>
  );
};

export default Input;
