import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

import { Footer, Header } from 'app/components';
import { Home, Organizations, ContactUs } from 'app/pages';

import { APP_ROUTES } from 'app/constants';

function App() {
  const location = useLocation();

  useEffect(() => {
    const loader = document.querySelector('.loader-wrap');
    if (loader) {
      loader.classList.add('_animating');
      setTimeout(() => {
        loader.remove();
      }, 2000);
    }
  }, []);

  return (
    <>
      <Header />
      <Routes>
        <Route path={APP_ROUTES.Home} element={<Home />} />
        <Route path={APP_ROUTES.Organizations} element={<Organizations />} />
        <Route path={APP_ROUTES.ContactUs} element={<ContactUs />} />
        <Route path="*" element={<Navigate to={APP_ROUTES.Home} replace />} />
      </Routes>
      {location?.pathname !== APP_ROUTES.ContactUs && (
        <Footer />
      )}
    </>
  );
}

export default App;
