import React from 'react';
import Select, { components as ReactSelectComponents } from 'react-select';
import cx from 'classnames';
import { Field } from 'formik';

import Icon from '../Icon';

import './style.scss';

const DropdownIndicator = (props) => {
  return (
    <ReactSelectComponents.DropdownIndicator {...props}>
      <Icon icon="arrow-down" />
    </ReactSelectComponents.DropdownIndicator>
  );
};

const SelectContainer = ({ children, ...props }) => (
  <ReactSelectComponents.SelectContainer {...props}>
    {children}
    <i className="control-select__border" />
  </ReactSelectComponents.SelectContainer>
);

const ReactSelect = ({
  name,
  className,
  onChange: callback,
  options,
  valueKey,
  useObjectValue = false,
  components,
  disabled,
  isMulti = false,
  placeholder,
  clearable = false,
  menuIsOpen,
  isOptionDisabled,
  isSearchable = false,
  ...rest
}) => {
  const selectWrapClass = cx('control-select', {
    'control-disabled': disabled,
  });

  const getValue = (value) => {
    if (!Array.isArray(options)) {
      return null;
    }
    const searchValue = useObjectValue ? value[valueKey] : value;
    const val = options.find((o) => o[valueKey] === searchValue);
    if (!val) {
      return null;
    }
    return val;
  };

  return (
    <div className={selectWrapClass}>
      <Field name={name}>
        {({ field: { value }, meta, form }) => (
          <>
            <Select
              {...rest}
              classNamePrefix="cs"
              className={cx('cs-container', className, {
                _error: meta?.touched && meta?.error,
              })}
              value={getValue(value)}
              options={options}
              onChange={(event) => {
                form.setFieldValue(name, event[valueKey ?? 'value']);
                if (callback) {
                  callback(event);
                }
              }}
              onBlur={(event) => {
                event.preventDefault();
                form.setFieldTouched(name, true);
              }}
              components={{ ...components, DropdownIndicator, SelectContainer }}
              isDisabled={disabled}
              isMulti={isMulti}
              placeholder={placeholder}
              isClearable={clearable}
              menuIsOpen={menuIsOpen}
              isOptionDisabled={isOptionDisabled}
              isSearchable={isSearchable}
              blurInputOnSelect={false}
            />
            {meta?.touched && meta?.error && (
              <div className="control-error">{meta.error}</div>
            )}
          </>
        )}
      </Field>
    </div>
  );
};

export default ReactSelect;
