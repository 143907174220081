import React from 'react';
import cx from 'classnames';

import './style.scss';

const Button = ({
  className,
  type = 'button',
  iconBtn = false,
  theme = 'primary',
  loading,
  blocked,
  children,
  ...rest
}) => {
  return (
    <button
      type={type}
      className={cx(
        'btn',
        { 'btn-icon': iconBtn, [`btn-${theme}`]: true, _loading: loading, _blocked: blocked },
        className
      )}
      {...rest}
    >
      <span className="btn__content">{children}</span>
      {loading && (
        <span className="btn__loader" />
      )}
    </button>
  );
};

export default Button;
