import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import cx from 'classnames';
import { useMediaQuery } from 'react-responsive';

import logoBlack from 'app/assets/logo-black.png';
import logoWhite from 'app/assets/logo.png';

import { NAVIGATION, APP_ROUTES } from 'app/constants';
import { Button, Icon, Responsive } from 'app/components';

import './style.scss';

const Header = () => {
  const location = useLocation();
  const [menuOpened, setMenuOpened] = useState(false);
  const notDesktop =  useMediaQuery({ maxWidth: Responsive.TABLET_MAX_WIDTH });

  const whiteTheme = location?.pathname === APP_ROUTES.BurningMan;

  useEffect(() => {
    if (menuOpened) {
      document.body.classList.add('_menu-opened');
    } else {
      document.body.classList.remove('_menu-opened');
    }
  }, [menuOpened]);

  const toggleMenu = () => {
    setMenuOpened(prev => !prev);
  };

  return (
    <header className={cx('header-section', { '_white-theme': whiteTheme })}>
      <div className="wrapper">
        <div className="header-content">
          <Link to={APP_ROUTES.Home} className="header-logo">
            <img src={whiteTheme ? logoWhite : logoBlack} alt=" We Are One" />
          </Link>
          <HashLink to="/#create-account" className="visible-tablet header-create-acc-btn">
            <Button>Create account</Button>
          </HashLink>
          <button 
            type="button" 
            className={cx('header-toggle-menu-btn hidden-desktop', { _opened: menuOpened })} 
            onClick={toggleMenu}
          >
            <Icon icon={menuOpened ? 'close' : 'menu'} />
          </button>
          <div className="header-nav">
            <ul className="header-menu">
              {NAVIGATION.map(({label, url, icon}) => (
                <li key={label}>
                  <Link to={url} onClick={notDesktop ? toggleMenu : undefined}>
                    {icon ? <Icon icon={icon} /> : null}
                    {label}
                  </Link>
                </li>
              ))}
              <li>
                <HashLink 
                  to="/#create-account" 
                  className="hidden-tablet"
                  onClick={notDesktop ? toggleMenu : undefined} 
                >
                  <Button>Create account</Button>
                </HashLink>
              </li>
            </ul>
          </div>
          <i className="header-tablet-menu-overlay visible-tablet"  onClick={toggleMenu}></i>
        </div>
      </div>
    </header>
  );
};

export default Header;