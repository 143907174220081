import React from 'react';

import { useLogScreenView } from 'app/hooks/analytics';
import {
  OHero,
  OInfo,
  OIntro,
  OCards,
  OEvents,
  OScanner,
  OGallery,
  OApplication,
  ScrollTopButton,
} from 'app/components';

const Home = () => {
  useLogScreenView('Organizations');

  return (
    <>
      <OHero />
      <OIntro />
      <OCards />
      <OScanner />
      <OGallery />
      <OEvents />
      <OInfo />
      <OApplication />
      <ScrollTopButton />
    </>
  );
};

export default Home;