import { useEffect } from 'react';
import { logEvent } from 'firebase/analytics';

import { analytics } from 'app/utils';
import { LogEvent } from 'app/constants';

export const useLogScreenView = (screen) => {
  useEffect(() => {
    logEvent(analytics, LogEvent.ScreenView, { firebase_screen: screen });
  }, [screen]);
};
