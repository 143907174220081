import React from 'react';
import PhoneInput from 'react-phone-number-input';
import { Field } from 'formik';
import cx from 'classnames';

import './style.scss';

const PhoneInputField = ({ name, onFocus }) => {
  return (
    <Field name={name}>
      {({ meta, field: { value, onBlur }, form: { setFieldValue } }) => (
        <div className={cx('control-phone', {_error: meta?.touched && meta?.error})}>
          <PhoneInput
            name={name}
            value={value}
            international
            onBlur={onBlur}
            onFocus={onFocus}
            defaultCountry="US"
            withCountryCallingCode
            countryCallingCodeEditable={false}
            onChange={(value) => {
              setFieldValue(name, value);
            }}
          />
          {meta?.touched && meta?.error && (
            <div className="control-error">{meta.error}</div>
          )}
        </div>  
      )}
    </Field>
  );
}

export default PhoneInputField;