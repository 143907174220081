import React from 'react';
import VerificationInput from 'react-verification-input';
import { Field } from 'formik';

import './style.scss';

const VerificationInputField = ({ name }) => {
  return (
    <div className="control-verification-code">
      <Field name={name}>
        {({ meta, field: { value, onChange }, form: { setFieldTouched } }) => (
          <>
            <VerificationInput
              autoFocus
              validChars="0-9"
              length={6}
              placeholder="0"
              removeDefaultStyles
              value={value}
              classNames={{
                container: 'code-input',
                character: 'code-item',
                characterInactive: 'code-item__inactive',
                characterSelected: 'code-item__selected'
              }}
              inputProps={{
                name,
                onChange,
              }}
              onBlur={() => {
                setFieldTouched(name, true);
              }}
            />
            {meta?.touched && meta?.error && (
              <div className="control-error">{meta.error}</div>
            )}
          </>
        )}
      </Field>
    </div>
  );
}

export default VerificationInputField;