import { useMediaQuery } from 'react-responsive';

const MOBILE_MAX_WIDTH = 767;
const TABLET_MAX_WIDTH = 1279;

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: TABLET_MAX_WIDTH + 1 });
  return isDesktop ? children : null;
};

const NotDesktop = ({ children }) => {
  const isNotDesktop = useMediaQuery({ maxWidth: TABLET_MAX_WIDTH });
  return isNotDesktop ? children : null;
};

const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: MOBILE_MAX_WIDTH + 1, maxWidth: TABLET_MAX_WIDTH });
  return isTablet ? children : null;
};

const TabletUp = ({ children }) => {
  const isTabletUp = useMediaQuery({ minWidth: MOBILE_MAX_WIDTH + 1 });
  return isTabletUp ? children : null;
};

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: MOBILE_MAX_WIDTH });
  return isMobile ? children : null;
};

export { Desktop, NotDesktop, Tablet, Mobile, TabletUp, MOBILE_MAX_WIDTH, TABLET_MAX_WIDTH };
