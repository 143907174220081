import React from 'react';
import { ReactComponent as InstagramIcon } from 'app/assets/icons/instagram.svg';
import { ReactComponent as TwitterIcon } from 'app/assets/icons/twitter.svg';
import { ReactComponent as FacebookIcon } from 'app/assets/icons/facebook.svg';
import { ReactComponent as MenuIcon } from 'app/assets/icons/menu.svg';
import { ReactComponent as CloseIcon } from 'app/assets/icons/close.svg';
import { ReactComponent as CloseCardIcon } from 'app/assets/icons/close-card.svg';
import { ReactComponent as OpenCardIcon } from 'app/assets/icons/open-card.svg';
import { ReactComponent as OpenCardWhiteIcon } from 'app/assets/icons/open-card-white.svg';
import { ReactComponent as ArrowDownIcon } from 'app/assets/icons/arrow-down.svg';
import { ReactComponent as AttachmentIcon } from 'app/assets/icons/attachment.svg';
import { ReactComponent as TrashIcon } from 'app/assets/icons/trash.svg';
import { ReactComponent as SweetcodeIcon } from 'app/assets/icons/sweetcode.svg';
import { ReactComponent as FireworksIcon } from 'app/assets/icons/fireworks.svg';
import { ReactComponent as ScrollTopIcon } from 'app/assets/icons/scroll-top.svg';

const getIcon = (iconName) => {
  switch (iconName) {
    case 'instagram':
      return <InstagramIcon />;
    case 'twitter':
      return <TwitterIcon />;
    case 'facebook':
      return <FacebookIcon />;
    case 'menu':
      return <MenuIcon />;
    case 'close':
      return <CloseIcon />;
    case 'close-card':
      return <CloseCardIcon />;
    case 'open-card':
      return <OpenCardIcon />;
    case 'open-card-white':
      return <OpenCardWhiteIcon />;
    case 'arrow-down':
      return <ArrowDownIcon />;
    case 'attachment':
      return <AttachmentIcon />;
    case 'trash':
      return <TrashIcon />;
    case 'sweetcode':
      return <SweetcodeIcon />;
    case 'fireworks':
      return <FireworksIcon />;
    case 'scroll-top':
      return <ScrollTopIcon />;
    default:
      return <span />;
  }
};

const Icon = ({ icon }) =>
  React.cloneElement(getIcon(icon), { className: `${icon}-icon` });

export default Icon;
