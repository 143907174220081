import React from 'react';

import { auth } from 'app/utils';
import * as firestore from 'app/firestore';
import { CreateAccountForm } from 'app/components';

import imgM from 'app/assets/ca-bg-m.png';
import imgT from 'app/assets/ca-bg-t.png';
import imgD from 'app/assets/ca-bg-d.png';

import './style.scss';

const HCreateAccount = () => {
  const createAccount = async (values, verification) => {
    try {
      const { code, phone, email } = values;

      await verification?.confirm(code);
      await firestore.submitUser({ phone, email, host: 'create-account' });
      await auth.currentUser.delete();

      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  return (
    <section className="h-account-section" id="create-account">
      <div className="wrapper">
        <div className="h-account-content">
          <CreateAccountForm withRecaptcha onSumbit={createAccount} />
          <div className="h-account-content__img">
            <picture>
              <source media="(max-width:767px)" srcSet={imgM} />
              <source media="(max-width:1279px)" srcSet={imgT} />
              <img src={imgD} alt="img" />
            </picture>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HCreateAccount;
