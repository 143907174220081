import * as Yup from "yup";
import { email, phone, varificationCode } from "app/utils";

const codeValidation = Yup.object().shape({
  code: varificationCode,
});

const phoneValidation = Yup.object().shape({
  email,
  phone,
});

export const validationSchema = (codeSent) =>
  codeSent ? codeValidation : phoneValidation;
