import React from 'react';
import cx from 'classnames';

import { useScrollPosition } from 'app/hooks/common';

import { Icon } from 'app/components';

import './style.scss';

const ScrollTopButton = ({ onClick }) => {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

  const position = useScrollPosition();

  return (
    <div className={cx('scroll-top', { _visible: position > window.innerHeight })}>
      <button 
        type="button"
        className="scroll-top-btn" 
        onClick={() => {
          scrollTop();
          if (onClick) {
            onClick();
          }
        }}
      >
        <Icon icon="scroll-top" />
      </button>
    </div>
  );
};

export default ScrollTopButton;