import React, { useState } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';

import { contactUs } from 'app/firestore';
import { required, email } from 'app/utils';
import { useLogScreenView } from 'app/hooks/analytics';
import { ContactPageImg, InputField, Button } from 'app/components';

import './style.scss';

const validationSchema = Yup.object().shape({
  fullName: required,
  email,
  message: required,
});

const ContactUs = () => {
  useLogScreenView('ContactUs');
  const [successVisible, setSuccessVisible] = useState(false);

  const onSubmitHandler = async (values, { setSubmitting }) => {
    try {
      await contactUs({
        email: values.email,
        name: values.fullName,
        message: values.message,
      });
      setSuccessVisible(true);
    } catch (error) {
      alert(error.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <section className="cp-section">
      <div className="wrapper cp-layout">
        <div className="cp-layout__img">
          <ContactPageImg />
        </div>
        <div className="cp-layout__form">
          <div className="cp-title">
            <h1>Contact Us</h1>
            <p>
              <a href="mailto:weareone@gmail.com">weareone@gmail.com</a>
            </p>
          </div>
          {successVisible ? (
            <div className="cp-form-success">
              <h2>Thanks for the application!</h2>
              <p>We will review it within 3 business days.</p>
              <Button
                type="button"
                theme="secondary"
                onClick={() => setSuccessVisible(false)}
              >
                Okay
              </Button>
            </div>
          ) : (
            <Formik
              enableReinitialize
              initialValues={{}}
              validationSchema={validationSchema}
              onSubmit={onSubmitHandler}
            >
              {(props) => (
                <Form className="cp-form" autoComplete="off">
                  <div className="cp-form-row">
                    <InputField placeholder="Full Name" name="fullName" />
                  </div>
                  <div className="cp-form-row">
                    <InputField type="email" placeholder="Email" name="email" />
                  </div>
                  <div className="cp-form-row">
                    <InputField textarea placeholder="Message" name="message" />
                  </div>
                  <div className="cp-form-row _btn">
                    <Button
                      type="submit"
                      disabled={!props.dirty || !props.isValid}
                      loading={props.isSubmitting}
                    >
                      Send
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
