import React, { useRef } from 'react';
import { Field, useFormikContext } from 'formik';
import cx from 'classnames';

import Icon from '../Icon';

import './style.scss';

const InputFile = ({
  name,
  onChange: callback,
  disabled,
}) => {
  const inputRef = useRef();
  const { values, setFieldValue } = useFormikContext();

  const fileWrapClass = cx('control-file', {
    'control-disabled': disabled,
  });

  return (
    <div className={fileWrapClass}>
      <Field name={name}>
        {({ meta, form: { setFieldValue } }) => (
          <>
            <input
              type="file"
              ref={inputRef}
              name={name}
              disabled={disabled}
              onChange={(event) => {
                setFieldValue(name, event.currentTarget.files[0]);
                if (callback) {
                  callback(event);
                }
              }} 
            />
            {meta?.touched && meta?.error && (
              <div className="control-error">{meta.error}</div>
            )}
          </>
        )}
      </Field>
      {values[name] ? (
        <div className="control-file-attachemnt">
          <div className="control-file-attachemnt__name">
            <Icon icon="attachment" />
            <span>{values[name].name}</span>
          </div>
          <div 
            className="control-file-attachemnt__remove"
            onClick={() => {
              inputRef.current.value = null;
              setFieldValue(name, undefined);
            }}
          >
            <Icon icon="trash" />
          </div>
        </div>
      ) : (
        <span className="control-file-btn" onClick={() => inputRef.current.click()}>
          <Icon icon="attachment" />
          Attach file
        </span>
      )}
    </div>
  );
};

export default InputFile;
