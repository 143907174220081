import React, { useEffect, useRef } from 'react';

import { gsap, ScrollTrigger } from 'app/lib/gsap';

import './style.scss';

const OGallery = () => {
  const gallerSection = useRef();
  const gallerScale = useRef();
  const rowTop = useRef();
  const rowBtm = useRef();

  useEffect(() => {
    let scrollTrigger
    ScrollTrigger.matchMedia({
      '(max-width: 1279px)': function() {
        scrollTrigger = gsap.timeline({
          scrollTrigger: {
            trigger: gallerSection.current,
            scrub: true,
            start: 'top center-=150px',
            end: 'bottom top',
          },
        })
        .to(
          rowTop.current,
          {
            translateX: '200px',
            duration: 2,
          },
          0
        )
        .to(
          rowBtm.current,
          {
            scale: 1,
            duration: 0.25,
          },
          0
        )
        .to(
          gallerScale.current,
          {
            scale: 1,
            duration: 0.8,
          },
          0.15
        )
        .to(
          rowBtm.current,
          {
            translateX: '-100px',
            duration: 0.5,
          },
          0.85
        );
      },
      '(min-width: 1280px)': function() {
        scrollTrigger = gsap.timeline({
          scrollTrigger: {
            trigger: gallerSection.current,
            scrub: true,
            start: 'top center-=150px',
            end: 'bottom top',
          },
        })
        .to(
          rowTop.current,
          {
            translateX: '350px',
            duration: 2,
          },
          0
        )
        .to(
          rowBtm.current,
          {
            scale: 1,
            duration: 0.25,
          },
          0
        )
        .to(
          gallerScale.current,
          {
            scale: 1,
            duration: 0.8,
          },
          0.15
        )
        .to(
          rowBtm.current,
          {
            translateX: '-203px',
            duration: 1,
          },
          0.85
        );
      },
    });
      
    return () => {
      ScrollTrigger.clearMatchMedia();
      scrollTrigger.scrollTrigger.kill();
    };
  }, []);

  return (
    <section className="o-img-gallery-section" ref={gallerSection}>
      <div className="o-img-gallery-content">
        <div className="o-img-gallery-scale" ref={gallerScale}>
          <div className="o-img-gallery-row _top" ref={rowTop} />
          <div className="o-img-gallery-row _btm" ref={rowBtm} />
        </div>
      </div>
    </section>
  );
};

export default OGallery;