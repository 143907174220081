import React, { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';

import { Icon } from 'app/components';

import card1FrontImg from 'app/assets/cards/card-1-front-img.png';
import card1Back from 'app/assets/cards/card-1-back-img.png';
import card3Front from 'app/assets/cards/card-3-front-img.png';
import card3Back from 'app/assets/cards/card-3-back-img.png';

import './style.scss';

const OCards = () => {
  const [openedCard, setOpenedCard] = useState(undefined);
  const [openedCardEl, setOpenedCardEl] = useState(undefined);
  const [openedCardAnimating, setOpenedCardAnimating] = useState(undefined);

  const onScroll = useCallback(() => {
    if (!openedCardEl) {
      return;
    }
    const rect = openedCardEl.getBoundingClientRect();
    const top = rect.top;
    const bottom = rect.bottom;
    const indent = rect.height / 4;
    
    if ((top + indent) <= 0 || (bottom - window.innerHeight - indent) >= 0) {
      closeCard();
    }
  }, [openedCardEl]);

  useEffect(() => {
    if (!openedCardEl) {
      return;
    }
    window.addEventListener('scroll', onScroll, false);

    return () => {
      window.removeEventListener('scroll', onScroll, false);
    };
  }, [openedCardEl, onScroll]);

  const openCard = (cardIndex, e) => {
    setOpenedCard(cardIndex);
    setOpenedCardAnimating(cardIndex);
    const cardEl = e.currentTarget.closest('.o-card');
    if (cardEl) {
      cardEl.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
      setTimeout(() => setOpenedCardEl(() => cardEl), 800);
    }
  };

  const closeCard = () => {
    setOpenedCard(undefined);
    setOpenedCardEl(undefined);
    setTimeout(function () {
      setOpenedCardAnimating(undefined);
    }, 800);
  };

  return (
    <section className="o-cards-section">
      <div className="wrapper">
        <div className="o-cards-content">
          <div className="o-cards-grid">
            <div className={cx('o-card first-card', { _opened: openedCard === 1, _animating: openedCardAnimating === 1 })}>
              <div className="o-card-inner">
                <div className="o-card-front" onClick={(e) => openCard(1, e)}>
                  <p className="_title">Easy to create and introduce your organization to citizens of the app.</p>
                  <p className="_txt">Get complete and straightforward control over the content of your organization.</p>
                  <div className="_img">
                    <img src={card1FrontImg} alt="" />
                  </div>
                  <span className="o-card-open-btn">
                    <Icon icon="open-card" />
                  </span>
                </div>
                <div className="o-card-back">
                  <p>
                    Complete management of the organization takes place in the admin panel. Opportunity 
                    to create publications, split donations into small goals, promote your organization 
                    on the platform, create events, and bring people together.
                  </p>
                  <div className="_img">
                    <img src={card1Back} alt="" />
                  </div>
                  <span className="o-card-close-btn" onClick={closeCard}>
                    <Icon icon="close-card" />
                  </span>
                </div>
              </div>
            </div>
            <div className={cx('o-card _half second-card', { _opened: openedCard === 2, _animating: openedCardAnimating === 2 })}>
              <div className="o-card-inner">
                <div className="o-card-front" onClick={(e) => openCard(2, e)}>
                  <p>New audience with new world views.</p>
                  <span className="o-card-open-btn">
                    <Icon icon="open-card-white" />
                  </span>
                </div>
                <div className="o-card-back">
                  <p>
                    The <strong>We Are One</strong> app has focused on a new generation of users. Our goal is to bring people 
                    together in one place and positively impact the world. <b>We Are One</b> is a new social network that combines 
                    lifestyle content and charitable organizations.
                  </p>
                  <span className="o-card-close-btn" onClick={closeCard}>
                    <Icon icon="close-card" />
                  </span>
                </div>
              </div>
            </div>
            <div className={cx('o-card _half third-card', { _opened: openedCard === 3, _animating: openedCardAnimating === 3 })}>
              <div className="o-card-inner">
                <div className="o-card-front" onClick={(e) => openCard(3, e)}>
                  <div className="_img">
                    <img src={card3Front} alt="" />
                  </div>
                  <p>Break down your $ total goal into smaller donation targets</p>
                  <span className="o-card-open-btn">
                    <Icon icon="open-card" />
                  </span>
                </div>
                <div className="o-card-back">
                  <div className="_img">
                    <img src={card3Back} alt="" />
                  </div>
                  <p>
                    We encourage users to make one-time and regular donations. 
                    Donations tracking also takes place in the admin panel.
                  </p>
                  <span className="o-card-close-btn" onClick={closeCard}>
                    <Icon icon="close-card" />
                  </span>
                </div>
              </div>
            </div>
            <i className="o-cards-grid-overlay" onClick={closeCard}></i>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OCards;