import React from 'react';

import hero from 'app/assets/o-hero.png';

import './style.scss';

const OHero = () => {
  return (
    <section className="o-hero-section">
      <div className="wrapper">
        <div className="o-hero-content">
          <h1 className="o-hero-title">
            Our Mission is to accelerate humankind’s transition to a positive global society.
          </h1>
          <div className="o-hero-img">
            <img src={hero} alt="hero" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default OHero;