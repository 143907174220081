import React, { useEffect, useRef } from 'react';
import { Parallax } from 'react-scroll-parallax';

import { Responsive } from 'app/components';
import { gsap, ScrollTrigger } from 'app/lib/gsap';

import heartImgUrl from 'app/assets/h-parallax/heart.svg';
import treeImgUrl from 'app/assets/h-parallax/tree.png';
import screenImgUrl from 'app/assets/h-parallax/screen.png';

import './style.scss'

const HParallaxTree = () => {
  const parallaxImg = useRef();
  const parallaxCircleLogo = useRef();
  const treeSection = useRef();
  const treeGradient = useRef();
  const heart = useRef();
  const heartImg = useRef();
  
  useEffect(() => {
    const counterAnimation = gsap.to('.h-phone-counter__value', { 
      innerText: 9999, 
      duration: 2, 
      snap: {
        innerText: 1
      },
      paused: true,
      // onStart: () => {
      //   document.body.classList.add('_freezed_scroll');
      // },
      onComplete: () => {
        // document.body.classList.remove('_freezed_scroll');
        const counterValues = document.querySelectorAll('.h-phone-counter__value');
  
        for (const counterValue of counterValues) {
          counterValue.classList.add('_completed');
        }
      },
    });

    let pinnedLogoScrollTrigger, treeGradientScrollTrigger, pinnedLogoTreeScrollTrigger;

    ScrollTrigger.matchMedia({
      '(max-width: 767px)': () => {
        pinnedLogoScrollTrigger = ScrollTrigger.create({
          trigger: parallaxImg.current,
          start: 'top top', 
          end: 'top top-=670px',
          pin: parallaxCircleLogo.current,
          onEnter: () => {
            parallaxCircleLogo.current.classList.add('_pinned');
          },
          onLeave: () => {
            parallaxCircleLogo.current.classList.remove('_pinned');
            counterAnimation.play();
          },
          onEnterBack: () => {
            parallaxCircleLogo.current.classList.add('_pinned');
          },
          onLeaveBack: () => {
            parallaxCircleLogo.current.classList.remove('_pinned');
          },
        });

        treeGradientScrollTrigger = gsap.timeline({
          scrollTrigger: {
            trigger: treeSection.current,
            scrub: true,
            start: 'top center',
            end: 'bottom bottom',
            once: true,
            onLeave: () => {
              treeGradient.current.classList.add('_animate');
              heartImg.current.classList.add('_animate');
            },
          },
        });
      },
      '(min-width: 768px) and (max-width: 1279px)': () => {
        pinnedLogoScrollTrigger = ScrollTrigger.create({
          trigger: parallaxImg.current,
          start: 'top top', 
          end: 'top top-=662px',
          pin: parallaxCircleLogo.current,
          onEnter: () => {
            parallaxCircleLogo.current.classList.add('_pinned');
          },
          onLeave: () => {
            parallaxCircleLogo.current.classList.remove('_pinned');
            counterAnimation.play();
          },
          onEnterBack: () => {
            parallaxCircleLogo.current.classList.add('_pinned');
          },
          onLeaveBack: () => {
            parallaxCircleLogo.current.classList.remove('_pinned');
          },
        });

        treeGradientScrollTrigger = gsap.timeline({
          scrollTrigger: {
            trigger: treeSection.current,
            scrub: true,
            start: 'top center',
            end: 'top center-=590px',
            once: true,
            onLeave: () => {
              treeGradient.current.classList.add('_animate');
              heartImg.current.classList.add('_animate');
            },
          },
        })
        .to(
          heart.current,
          {
            opacity: 1,
            translateY: '156px',
            scale: 1,
            duration: 1,
            repeat: false,
          },
          0
        );

        pinnedLogoTreeScrollTrigger = gsap.timeline({
          scrollTrigger: {
            trigger: treeSection.current,
            scrub: true,
            start: 'top center+=124px',
            end: 'top center-=200px',
            pin: parallaxCircleLogo.current,
          },
        })
      },
      '(min-width: 1280px)': () => {
        const startPoint = 465 - (Math.max(465 - 92, (window.innerHeight / 2 ) - 92));
        const startValue= startPoint >= 0 ? '-=' + startPoint : '+=' + Math.abs(startPoint)
        pinnedLogoScrollTrigger = ScrollTrigger.create({
          trigger: parallaxImg.current,
          start: 'top top' + startValue + 'px', 
          end: 'top top-=965px',
          pin: parallaxCircleLogo.current,
          preventOverlaps: true,
          onEnter: () => {
            parallaxCircleLogo.current.classList.add('_pinned');
          },
          onLeave: () => {
            parallaxCircleLogo.current.classList.remove('_pinned');
            counterAnimation.play();
          },
          onEnterBack: () => {
            parallaxCircleLogo.current.classList.add('_pinned');
          },
          onLeaveBack: () => {
            parallaxCircleLogo.current.classList.remove('_pinned');
          },
        });

        treeGradientScrollTrigger = gsap.timeline({
          scrollTrigger: {
            trigger: treeSection.current,
            scrub: true,
            start: 'top center',
            end: 'top center-=590px',
            once: true,
            onLeave: () => {
              treeGradient.current.classList.add('_animate');
              heartImg.current.classList.add('_animate');
            },
          },
        })
        .to(
          heart.current,
          {
            opacity: 1,
            translateY: '334px',
            scale: 1,
            duration: 1,
            repeat: false,
          },
          0
        );

        pinnedLogoTreeScrollTrigger = gsap.timeline({
          scrollTrigger: {
            trigger: treeSection.current,
            scrub: true,
            start: 'top center+=300px',
            end: 'top center-=300px',
            pin: parallaxCircleLogo.current,
          },
        })
      },
    });
    
    return () => {
      counterAnimation?.kill();
      ScrollTrigger.clearMatchMedia();
      pinnedLogoScrollTrigger?.kill();
      treeGradientScrollTrigger?.scrollTrigger?.kill();
      pinnedLogoTreeScrollTrigger?.scrollTrigger?.kill();
    };
  }, []);

  return (
    <>
      <section className="h-animation-scrolltrigger">
        <section className="h-parallax-section">
          <div className="h-parallax-img" ref={parallaxImg}>
            <i className="_circle" />
            <Responsive.TabletUp>
              <div className="_blue-ball-1">
                <Parallax translateY={['30%', '-30%']}>
                  <i />
                </Parallax>
              </div>
            </Responsive.TabletUp>
            <Responsive.TabletUp>
              <div className="_blue-ball-2">
                <Parallax translateY={['-30%', '30%']}>
                  <i />
                </Parallax>
              </div>
            </Responsive.TabletUp>
            <Responsive.TabletUp>
              <div className="_circle-s">
                <Parallax rotate={['0deg', '-30deg']}>
                  <i />
                </Parallax>
              </div>
            </Responsive.TabletUp>
            <div className="_white-ball-1">
              <Responsive.Mobile>
                <Parallax translateY={['0%', '20%']} translateX={['0%', '-20%']}>
                  <i />
                </Parallax>
              </Responsive.Mobile>
              <Responsive.TabletUp>
                <Parallax translateY={['-20%', '20%']} translateX={['0%', '-20%']}>
                  <i />
                </Parallax>
              </Responsive.TabletUp>
            </div>
            <Responsive.Desktop>
              <div className="_white-ball-2">
                <Parallax translateX={['0%', '-40%']} translateY={['0%', '10%']}>
                  <i />
                </Parallax>
              </div>
            </Responsive.Desktop>
            <Responsive.Desktop>
              <div className="_disk">
                <Parallax translateX={['0%', '-30%']} translateY={['0%', '-20%']}>
                  <i />
                </Parallax>
              </div>
            </Responsive.Desktop>
            <div className="_disk-big">
              <Parallax translateY={['0%', '20%']} translateX={['-20%', '20%']}>
                <i />
              </Parallax>
            </div>
            <div className="_disk-small">
              <Parallax translateY={['0%', '-20%']} translateX={['0%', '30%']}>
                <i />
              </Parallax>
            </div>
            <div className="_cursor">
              <Parallax translateY={['0%', '20%']} translateX={['-10%', '20%']}>
                <i />
              </Parallax>
            </div>
            <Responsive.TabletUp>
              <div className="_cone">
                <Parallax translateY={['0%', '50%']} rotate={['0deg', '40deg']}>
                  <i />
                </Parallax>
              </div>
            </Responsive.TabletUp>
          </div>
          <div className="h-parallax-content">
            <div className="wrapper">
              <p>
                <b>Energia</b> is a currency built by we are one and earned by <b>contributing.</b>
              </p>
            </div>
          </div>
        </section>
        <section className="h-phone-section">
          <div className="wrapper">
            <div className="h-phone-content">
              <div className="h-phone-counter _left hidden-mobile">
                <p className="h-phone-counter__value">0000</p>
              </div>
              <div className="h-phone-img">
                <img src={screenImgUrl} alt="screen" />
              </div>
              <div className="h-phone-counter _right">
                <p className="h-phone-counter__value">0000</p>
              </div>
            </div>
          </div>
        </section>
        <section className="h-tree-section" ref={treeSection}>
          <div className="wrapper">
            <div className="h-tree-content">
              <div className="h-tree-img">
                <i className="h-tree-img__gradient" ref={treeGradient} />
                <img src={treeImgUrl} alt="tree" />
              </div>
              <div className="h-tree-heart" ref={heart}>
                <img src={heartImgUrl} alt="heart" ref={heartImg} />
              </div>
            </div>
          </div>
        </section>
        <i className="h-parallax-circle-logo" ref={parallaxCircleLogo} />
      </section>
      <section className="h-tree-txt-section">
        <div className="wrapper">
          <div className="h-tree-txt-content">
            <p><b>Citizen of Life</b> is a <br className='hidden-tablet hidden-desktop'/> fundamental rethink of social currency, transforming it into a positive force 
            for humanity and the planet that will usher in a <b>new era</b> in human history.
            </p>
          </div>
        </div>
      </section>
    </>
  )
}

export default HParallaxTree;