import * as firestore from 'app/firestore';

export const uploadDocument = async (id, file) => {
  try {
    await firestore.uploadDocument(id, file);
    const url = await firestore.getDocumentUrl(id);
    return Promise.resolve({ url });
  } catch (error) {
    return Promise.reject(error);
  }
};
