import { httpsCallable } from 'firebase/functions';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import {
  updateEmail,
  signInAnonymously,
  signInWithPhoneNumber,
} from 'firebase/auth';

import { db, auth, storage, functions } from 'app/utils';

export const signIn = () => {
  return signInAnonymously(auth);
};

export const signInWithPhone = (phone, verifier) => {
  return signInWithPhoneNumber(auth, phone, verifier);
};

export const submitOrganization = (values) => {
  const collectionRef = collection(db, "Organizations");
  return addDoc(collectionRef, {
    submitType: 'Lead',
    website: values.websiteLink,
    createdAt: serverTimestamp(),
    name: values.organizationName,
    profitType: values.organizationType,
    ...(values.file && { documents: [values.file] }),    
    lead: { 
      email: values.email, 
      fullName: values.fullName, 
      submitUrl: window.location.host,
    },
  });
};

export const submitUser = (values) => {
  return addDoc(collection(db, "UsersLeads"), {
    ...values,
    createdAt: serverTimestamp(),
    host: values.host ?? window.location.host,
  });
};

export const updateAuthUser = ({ email }) => {
  return updateEmail(auth.currentUser, email);
};

export const uploadDocument = (id, file) => {
  const storageRef = ref(storage, `documents/${id}`);
  return uploadBytes(storageRef, file);
};

export const getDocumentUrl = (id) => {
  const storageRef = ref(storage, `documents/${id}`);
  return getDownloadURL(storageRef);
};

const contactUsCallable = httpsCallable(functions, "contactUs");
export const contactUs = ({ email, name, message }) =>
  contactUsCallable({ email, name, message });
