import React, { useEffect, useRef } from 'react';
import LocomotiveScroll from 'locomotive-scroll';
import { ParallaxProvider } from 'react-scroll-parallax';

import { useLogScreenView } from 'app/hooks/analytics';
import {
  HHero,
  HSlider,
  Citizens,
  HVisionMisson,
  HParallaxTree,
  HCreateAccount,
} from 'app/components';

const Home = () => {
  useLogScreenView('Citizen');
  const containerRef = useRef(null);

  useEffect(() => {
    if (!containerRef) {
      return;
    }
    const scroll = new LocomotiveScroll({
      el: containerRef.current,
    });
    return () => scroll.destroy();
  }, [containerRef]);

  return (
    <ParallaxProvider>
      <div className="home-page" data-scroll-container ref={containerRef}>
        <HHero />
        <Citizens />
        <HVisionMisson />
        <HSlider />
        <HParallaxTree />
        <HCreateAccount />
      </div>
    </ParallaxProvider>
  );
};

export default Home;