import React from 'react';
import { Parallax } from 'react-scroll-parallax';

import lines from 'app/assets/citizens/lines.svg';
import phone from 'app/assets/citizens/phone.png';
import card from 'app/assets/citizens/card.png';
import circle from 'app/assets/citizens/circle.png';
import nature from 'app/assets/citizens/nature.png';
import shine from 'app/assets/citizens/shine.svg';
import cardW from 'app/assets/citizens/card-w.png';
import cardB from 'app/assets/citizens/card-b.png';
import cardS from 'app/assets/citizens/card-s.png';
import cardP from 'app/assets/citizens/card-p.png';

import './style.scss';

const Citizens = () => {
  return (
    <section className="h-citizens-section" data-scroll-section>
      <div className="wrapper">
        <div className="h-citizens-content">
          <div className="h-citizens-content__txt">
            <h2>Citizens of Life</h2>
            <p>Discover, interact, and contribute with the good in humanity around you.</p>
          </div>
          <div className="h-citizens-content__img">
            <div className="h-citizens-img" data-scroll data-scroll-offset="75%">
              <div className="_lines-block">
                <img src={lines} alt="" />
              </div>
              <div className="_phone-block">
                <img src={phone} alt="" />
                <div className="_card-block">
                  <img src={card} alt="" />
                </div>
                <div className="_circle-block">
                  <img src={circle} alt="" />
                </div>
              </div>
              <div className="_nature-block">
                <img src={nature} className="_nature" alt="" />
                <img src={shine} className="_shine" alt="" />
              </div>
                <div className="_card-w-block">
                  <Parallax translateY={['0%', '35%']}>
                    <img src={cardW} alt="" />
                  </Parallax>
                </div>
              <div className="_card-b-block">
                <Parallax translateY={['0%', '-40%']}>
                  <img src={cardB} alt="" />
                </Parallax>
              </div>
              <div className="_card-s-block">
                <Parallax translateX={['0%', '40%']} translateY={['0%', '35%']}>
                  <img src={cardS} alt="" />
                </Parallax>
              </div>
              <div className="_card-p-block">
                <Parallax translateX={['0%', '-40%']}>
                  <img src={cardP} alt="" />
                </Parallax>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Citizens;